@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: var(--primary-color);
  min-height: 100vh;
  width: 100vw;
  color: white;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575e80;
  border-radius: 4px;
}

.hover-accent:hover {
  color: var(--call-to-action-color);
  opacity: 100%;
}

.hover-displace:hover {
  translate: 0 -4px;
}
.translate-8:hover {
  translate: 0 -8px;
}

.heroTxt {
  color: var(--text-header-color);
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  text-transform: capitalize;
}

.heroSubheader {
  color: var(--text-header-color);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 35.2px */
}

.navbrandtxt {
  color: var(--text-header-color);
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}

.navbrandtxt2 {
  color: var(--text-header-color);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}

.sticky-nav {
  background: var(--primary-color);
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust the z-index as needed */
}

.navitems {
  color: var(--text-header-color);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}

.searchBox,
filter {
  height: 60px;
  padding: 12px 0px;
  flex-direction: column;
  align-items: center;

  gap: 10px;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}

.searchPlaceHolder {
  display: flex;
  justify-content: flex-end; /* Align placeholder text to the right */
  align-items: center;
  color: var(--text-header-color);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  height: 60px;
  padding: 12px 20px 12px 0; /* Added right padding to move text away from the right edge */
  gap: 10px;
  background-color: var(--secondary-color);
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  padding-left: 15px;
}

.searchPlaceHolder::placeholder {
  padding-left: 15px;
  color: var(--text-header-color);
}

/* select element */
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

@media only screen and (max-width: 1024px) and (min-width: 1024px) {
  .cardPage {
    justify-content: space-evenly;
  }
  .searchPlaceHolder {
    width: 768px !important;
  }

  .filter {
    width: 830px !important;
    margin-left: 9%;
  }
  .searchBox,
  filter {
    width: 768px !important;
    padding-left: 20%;
  }
  .heroHeaders {
    padding-left: 10%;
  }
  .searchPlaceHolder::placeholder {
    margin-left: 250px !important;
  }

  .MagnifyingGlass {
    left: 105%;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1740px) {
  .fixedScrollArrow {
    right: 20%;
  }
}
@media only screen and (max-width: 1739px) and (min-width: 1471px) {
  .fixedScrollArrow {
    right: 15%;
  }
}

@media only screen and (max-width: 1470px) and (min-width: 1371px) {
  .fixedScrollArrow {
    right: 10%;
  }
}
@media only screen and (max-width: 1370px) and (min-width: 1001px) {
  .fixedScrollArrow {
    right: 5%;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 0px) {
  .fixedScrollArrow {
    right: 5.5%;
  }
}

@media only screen and (max-width: 320px) and (min-width: 320px) {
  .searchBox {
    width: 300px !important;
    margin-right: 20px;
  }

  .searchPlaceHolder {
    width: 300px !important;
    margin-right: 20px;
  }

  .filter {
    width: 300px !important;
    margin-right: 20px;
  }

  .MagnifyingGlass {
    left: 105%;
  }
}

@media only screen and (max-width: 820px) and (min-width: 820px) {
  .cardPage {
    justify-content: space-evenly;
  }
  .searchPlaceHolder {
    width: 700px !important;
  }
  .searchBox,
  filter {
    width: 700px !important;
    padding-left: 10%;
  }

  .heroHeaders {
    padding-left: 5% !important;
  }
  .searchPlaceHolder::placeholder {
    margin-left: 250px !important;
  }

  .MagnifyingGlass {
    left: 99.5%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 768px) {
  .cardPage {
    justify-content: space-evenly;
  }
  .searchPlaceHolder {
    width: 700px !important;
  }
  .searchBox,
  filter {
    width: 700px !important;
    padding-left: 10%;
  }
  .heroHeaders {
    padding-left: 5% !important;
  }
  .searchPlaceHolder::placeholder {
    margin-left: 250px !important;
  }

  .MagnifyingGlass {
    left: 95%;
  }
}

@media only screen and (max-width: 430px) and (min-width: 200px) {
  .cardPage {
    justify-content: space-evenly;
  }

  .MagnifyingGlass {
    left: 98%;
  }
}

@media only screen and (max-width: 1350px) and (min-width: 1024px) {
  .cardPage {
    justify-content: space-evenly;
  }
  .searchPlaceHolder {
    width: 830px !important;
  }
  .searchBox,
  filter {
    width: 830px !important;
    padding-left: 20%;
  }
  .heroHeaders {
    padding-left: 10% !important;
  }
  .searchPlaceHolder::placeholder {
    margin-left: 250px !important;
  }

  .MagnifyingGlass {
    left: 105%;
  }
}

.btn {
  border-radius: 20px;
  background: var(--secondary-color);
  border: 1px solid var(--call-to-action-color);
  color: var(--text-header-color);
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.btn2 {
  border-radius: 20px;
  background: var(--primary-color);
  color: var(--text-header-color);
  border: 1px solid var(--call-to-action-color);
  display: flex;
  height: 50px;
  width: 210px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.btn3 {
  border-radius: 20px;
  background: var(--secondary-color);
  color: var(--text-header-color);
  border: 1px solid #047d1c;
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.btn4 {
  border-radius: 20px;
  background: var(--primary-color);
  color: var(--text-header-color);
  border: 1px solid var(--call-to-action-color);
  display: flex;
  height: 50px;
  width: 150px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.btn5 {
  border-radius: 20px;
  background: var(--primary-color);
  border: 1px solid #047d1c;
  display: flex;
  height: 50px;
  width: 210px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.btn6 {
  border-radius: 20px;
  background: var(--primary-color);
  color: var(--text-header-color);
  border: 1px solid var(--call-to-action-color);
  display: flex;
  height: 60px;
  width: 255px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.faqheader {
  color: var(--text-header-color);
  font-size: 51px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  text-transform: capitalize;
}

.faqsubheader {
  color: var(--text-header-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 35.2px */
}

/* Paginate css */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-page {
  margin: 0 5px;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--secondary-color);
}

.pagination-page-link {
  cursor: pointer;
  padding: 5px;
}

/* guide page */
.guideTitle {
  color: var(--text-header-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 13.2px */
  margin-bottom: 20px;
}

.guideSubheader {
  color: var(--guide-text);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 13.2px */
}

.guideTagheader {
  color: var(--tag-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 13.2px */
}

.guideTagSubheader {
  color: var(--guide-text);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 13.2px */
  border: solid 1px var(--tag-border);
  background-color: var(--tag-border);
  padding: 10px;
  height: 30px;
  text-align: center;
  border-radius: 20px;
  padding-top: 5px;
}

/* Card Component CSS */
.cardlist {
  border-radius: 20px;
  background: var(--secondary-color);
}

.cardTitle {
  color: var(--text-header-color);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 30.8px */
  text-transform: capitalize;
}

.cardPriceTitle {
  color: var(--card-price-header-text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 13.2px */
}

.cardPriceWatchListTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 13.2px */
}

.cardPrice {
  color: var(--text-header-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.cardInfo {
  display: flex;
  padding: 20px 30px 25px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
  max-height: 325.56px;
  min-height: 325.56px;
}

/* Product Details Component */

@media only screen and (min-width: 350px) and (max-width: 390px) {
  .ProductDetailsDiv {
    margin-right: 5% !important;
  }
}
